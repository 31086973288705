<!--@Time : 2021/11/16 10:47-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
  <div>
    <Header></Header>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <p class="text-center text-h6">
          Energy Self-Sufficiency Contest Scores
        </p>
      </v-col>
      <v-col cols="12" md="12" lg="12">
        <v-container>
          <div id="container" style="position: relative">
            <Loading
                v-if="!data.length"
                :loading="loading"
            ></Loading>
          </div>
        </v-container>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-row v-if="$store.getters.team">
          <v-col cols="12" md="12" lg="12">
            <p class="text-h6 mt-8 mb-4 font-weight-bold text-uppercase">
              Your Performance
            </p>
            <v-alert
                prominent
                type="success"
                class="text-h6 font-weight-medium"
                v-text="'Score Earned: ' + Number(score).toFixed(3)"
            ></v-alert>
          </v-col>
        </v-row>
        <v-card outlined>
          <v-card-title>Net-Zero (50%)</v-card-title>
          <v-card-text>
            <p class="text-center text-h6 mb0">
              Net-Zero Measurements
            </p>
            <v-container>
              <div id="container_netzero">
                <Loading
                    v-if="!netzero_data.length"
                    :loading="loading"
                ></Loading>
              </div>
            </v-container>
            <SubContestLabel
                :score="netzero_score"
                v-if="$store.getters.team"
            ></SubContestLabel>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="$store.getters.team">
      <v-col cols="12" md="12" lg="12">
        <v-card outlined>
          <v-card-title>Off-Grid (50%)</v-card-title>
          <v-card-text>
            <p class="text-center text-h6">Off-Grid Measurements</p>
            <v-container>
              <v-progress-linear
                  :buffer-value="buffer_value"
                  :value="value"
                  stream
                  color="orange"
                  striped
                  rounded
                  height="20"
              ></v-progress-linear>
              <div>
                <span>START: 2022-08-08 10:00:00</span>
                <span class="float-right"
                >FINISH: 2022-08-10 10:00:00</span
                >
              </div>
            </v-container>
            <SubContestLabel
                class="mt-5"
                :score="offgrid_score"
            ></SubContestLabel>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Header from "../../components/Header";
import { Chart } from "@antv/g2";
import { DataView } from "@antv/data-set";
import Loading from "@/components/Loading";
import SubContestLabel from "../../components/SubContestLabel";
import { listNetZeroScore } from "@/api/solar/sensor";
import {Progress} from "@/api/solar/team";
export default {
  name: "Selfsufficiency",
  components: { SubContestLabel, Loading, Header },
  data() {
    return {
      isShow:
          this.$store.getters.groups === 1 ||
          this.$store.getters.groups === 2,
      netzero_score: 0,
      offgrid_score: 0,
      score: 0,
      data: [],
      netzero_data: [],
      chart: null,
      netzero_chart: null,
      loading: "loading...",
      starting_time: null,
      ending_time: null,
      timeProps: {
        useSeconds: true,
        ampmInTitle: true,
      },
      buffer_value: 0,
      value: 0,
    };
  },

  created() {
    this.getScore();
    this.getProgress()
  },

  mounted() {
    this.setChart();
    this.setNetZeroData();
    this.initWebSocket();

  },

  beforeDestroy() {
    //离开路由之前断开websocket连接
    this.websock.close();
    this.chart.destroy();
    this.netzero_chart.destroy();
  },

  watch: {
    "$store.getters.team": {
      handler() {
        this.websock.close();
        this.netzero_data = [];
        this.netzero_chart.changeData(this.netzero_data);
        this.initWebSocket();
        this.getScore()
        this.getProgress()
      },
    },
  },

  computed: {},

  methods: {
    async setChart() {
      this.chart = new Chart({
        container: "container",
        autoFit: true,
        height: 500,
      });
      this.chart.data(this.data);
      this.chart.scale("score", {
        min: 0,
        max: 100,
        tickInterval: 10,
      });
      this.chart.legend({
        position: "bottom",
        flipPage: false,
        items: [
          {
            name: "Net-Zero",
            marker: { style: { fill: "#e74c3c" } },
          },
          {
            name: "Off-Grid",
            marker: { style: { fill: "#6395F9" } },
          },
        ],
      });
      this.chart.coordinate().transpose();
      this.chart.tooltip({
        shared: true,
        showMarkers: false,
        customItems: (items) => {
          items.forEach((item)=>{
            item.value = Number(item.value).toFixed(3)
          })
          return items;
        },
      });
      this.chart.interaction("active-region");
      this.chart
          .interval()
          .adjust("stack")
          .position("team*score")
          .color("contest*team", (contest) => {
            if (contest === "Net-Zero") {
              return "#e74c3c";
            }
            if (contest === "Off-Grid") {
              return "#6395F9";
            }
            if (contest === "Total") {
              return "rgba(255,255,255,0)";
            }
          })
          .label("score", (score) => {
            if (score === 0) {
              return null;
            }
            return {
              content: score.toFixed(3),
              fill: "#07021a",
              position: "left",
            };
          });
      this.chart.render();
    },
    async setNetZeroData() {
      this.netzero_chart = new Chart({
        container: "container_netzero",
        autoFit: true,
        height: 500,
      });

      this.netzero_chart.data(this.netzero_data);
      this.netzero_chart.scale({
        time: {
          type: "time",
          mask: "YYYY-MM-DD HH:mm",
        },
        net_zero: {
          nice: true,
        },
      });

      this.netzero_chart.axis("net_zero", {
        title: {
          text: "Energy Balance (kWh)",
        },
        /* label: {
  formatter:(val) =>  {
    return val + 'kWh';
  },
}, */
      });

      this.netzero_chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      this.netzero_chart.legend({
        flipPage: false,
      });

      this.netzero_chart
          .line()
          .position("time*net_zero")
          .color("sensor_type")
          .shape("smooth");

      this.netzero_chart.option("slider", {
        trendCfg: {
          isArea: false,
        },
      });
      this.netzero_chart.render();
    },
    //初始化websocket
    async initWebSocket() {
      let url;
      if (this.$store.getters.team) {
        url = `${process.env.VUE_APP_WS_URL}/ws/sensordata/?token=${this.$store.getters.token}`;
      } else {
        url = `${process.env.VUE_APP_WS_URL}/ws/allsensor/?token=${this.$store.getters.token}`;
      }
      this.websock = new WebSocket(url);
      this.websock.onmessage = this.WebsocketOnMessage;
      this.websock.onopen = this.WebsocketOnOpen;
      this.websock.onerror = this.WebsocketOnError;
      this.websock.onclose = this.WebsocketClose;
    },
    WebsocketOnOpen(e) {
      console.log("建立连接", e);
      //连接建立之后执行send方法发送数据
      let actions = { type_id: "self-sufficiency" };
      if (this.$store.getters.team) {
        actions["team_id"] = this.$store.getters.team.id;
      }
      this.WebsocketSend(JSON.stringify(actions));
    },
    WebsocketOnError() {
      //连接建立失败重连
      this.initWebSocket();
    },
    WebsocketOnMessage(event) {
      //数据接收
      this.netzero_data = [];
      const data = JSON.parse(event.data);
      Object.values(data["sensor_data"]).forEach((value) => {
        this.netzero_data.push(...value["NetZero"]);
      });
      this.netzero_chart.changeData(this.netzero_data);
    },
    WebsocketSend(Data) {
      //数据发送
      this.websock.send(Data);
    },

    WebsocketClose(e) {
      //关闭
      console.log("断开连接", e);
    },

    async getScore() {
      listNetZeroScore()
          .then((res) => {
            this.data = new DataView().source(res).transform({
              type: "fold",
              fields: ["Net-Zero", "Off-Grid", "Total"], // 展开字段集
              key: "contest", // key字段
              value: "score", // value字段
              retains: ["team"], // 保留字段集，默认为除 fields 以外的所有字段
            }).rows;
            if (this.$store.getters.team) {
              res.forEach((item) => {
                if (
                    item.team === this.$store.getters.team.name_en
                ) {
                  this.netzero_score = item["Net-Zero"];
                  this.offgrid_score = item["Off-Grid"];
                  this.score = item.Total;
                }
              });
            }
            this.chart.changeData(this.data);
          })
          .catch();
    },

    async getProgress(){
      if (this.$store.getters.team !== null){
        Progress({team:this.$store.getters.team.id}).then((res)=>{
          this.buffer_value = res['buffer_value']
          this.value = res['value']
        })
      }
    },
  },
};
</script>

<style scoped>
</style>